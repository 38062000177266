import { default as aboutvf5lf9hGCDMeta } from "/opt/render/project/src/pages/about.vue?macro=true";
import { default as forgot1HF9rPpVrvMeta } from "/opt/render/project/src/pages/auth/forgot.vue?macro=true";
import { default as loginRWqn9SRgU9Meta } from "/opt/render/project/src/pages/auth/login.vue?macro=true";
import { default as logoutYaPt6wjRr3Meta } from "/opt/render/project/src/pages/auth/logout.vue?macro=true";
import { default as signupLbQ58vUW69Meta } from "/opt/render/project/src/pages/auth/signup.vue?macro=true";
import { default as confirmi09tHN14FUMeta } from "/opt/render/project/src/pages/confirm.vue?macro=true";
import { default as explorecLNEsqojJ0Meta } from "/opt/render/project/src/pages/explore.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as chooseIXdFc4WNr3Meta } from "/opt/render/project/src/pages/newflow/choose.vue?macro=true";
import { default as paymentLhIXhFH3t1Meta } from "/opt/render/project/src/pages/newflow/payment.vue?macro=true";
import { default as _1K9T3jzpFq9Meta } from "/opt/render/project/src/pages/onboard/1.vue?macro=true";
import { default as _20wIkVhIMuDMeta } from "/opt/render/project/src/pages/onboard/2.vue?macro=true";
import { default as _3R71KLFy0fpMeta } from "/opt/render/project/src/pages/onboard/3.vue?macro=true";
import { default as profileD6N3qH7A15Meta } from "/opt/render/project/src/pages/profile.vue?macro=true";
import { default as settings0miuIC4XHwMeta } from "/opt/render/project/src/pages/settings.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/render/project/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot",
    path: "/auth/forgot",
    component: () => import("/opt/render/project/src/pages/auth/forgot.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginRWqn9SRgU9Meta || {},
    component: () => import("/opt/render/project/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/opt/render/project/src/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    component: () => import("/opt/render/project/src/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/opt/render/project/src/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/opt/render/project/src/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "newflow-choose",
    path: "/newflow/choose",
    component: () => import("/opt/render/project/src/pages/newflow/choose.vue").then(m => m.default || m)
  },
  {
    name: "newflow-payment",
    path: "/newflow/payment",
    component: () => import("/opt/render/project/src/pages/newflow/payment.vue").then(m => m.default || m)
  },
  {
    name: "onboard-1",
    path: "/onboard/1",
    component: () => import("/opt/render/project/src/pages/onboard/1.vue").then(m => m.default || m)
  },
  {
    name: "onboard-2",
    path: "/onboard/2",
    component: () => import("/opt/render/project/src/pages/onboard/2.vue").then(m => m.default || m)
  },
  {
    name: "onboard-3",
    path: "/onboard/3",
    component: () => import("/opt/render/project/src/pages/onboard/3.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/opt/render/project/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/render/project/src/pages/settings.vue").then(m => m.default || m)
  }
]